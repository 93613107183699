<template>
  <Fragment>
    <!-- <span>{{restAPI}}</span> -->
    <h2>Branding</h2>
    <div class="module-xlarge">
      <h3>Logo</h3>
      <div class="logo-preview">
        <file-viewer-location :image-id="valuePopulate"  />
      </div>
      <!-- <file-upload label="Change Logo" :client-id="$route.params.id" :client-location-id="$route.params.locationID" filename="logo" :public="true" @change="onChangeLogo" /> -->
      <file-upload-logo label="Change Logo" :client-id="$route.params.id" :location-id="$route.params.locationID" filename="logo" :public="true" @change="onChangeLogo" />
    </div>
    <color-picker :color="branding.color" @change="onChangeColor" />
  </Fragment>
</template>
<script>
import { Fragment } from "vue-fragment";
import { ColorPicker } from "@/components";
// import { GET_CLIENT_LOCATION_BRANDING } from "@/modules/admin/locations/graph/query";
// import { SET_CLIENT_LOCATION_COLOR } from "@/modules/admin/locations/graph/mutations";
// import { SET_CLIENT_LOCATION_LOGO } from "@/modules/admin/clients/graph/mutations";
// import FileUpload from "@/components/files/FileUpload";
import FileUploadLogo from "@/components/files/FileUploadLogo";
import FileViewerLocation from "@/components/files/FileViewerLocation";
// import { hardDeleteFile } from "@/util/filesHelper";
import { restApi } from "@/http/http.config";
import {encodeWithParam} from "@/util/Base64Encoding";

export default {
  name: "LocationBranding",
  components: { FileViewerLocation, FileUploadLogo, ColorPicker, Fragment }, // , FileUpload
  // data: function () {
  //   var branding = { color: "#fff", logo: "" } 
    // branding = await this.getLocationBranding(this.$route.params.locationID);
  //   return branding;
  // },
  data: function () {
    return {
      branding: { color: "#fff", logo: "", name: "", path: "" },
    };
  },
  computed: {
    valuePopulate(){
      // console.log("inside valuePopulate this.branding.logo", this.branding.logo);
      return JSON.stringify({name: this.branding.name, path: this.branding.path});
    }
    // restAPI() {
      // this.getLocationBranding(this.$route.params.locationID);
      // return "";
    // },
  },
  mounted() {
    this.getLocationBranding(this.$route.params.locationID);
  },
  methods: {
    async getLocationBranding(locationID) {
      await restApi.post(`/location/getLocationBranding`, encodeWithParam({locationID})).then((data)=>{
        // console.log("getLocationBranding api getting finish",data);
        this.branding = data.data.data;
      });
    },
    refetch() {
      this.getLocationBranding(this.$route.params.locationID);
    },
    onChangeLogo(data) {
      // console.log("data onChangeLogo", data);
      restApi.post(`/location/updateLocationLogo`, encodeWithParam({id : this.$route.params.locationID, logo: data.fileID})).then(async ()=>{
        // console.log("data", data);
        if(this.branding.logo)
          await restApi.post(`/employee/deleteSingleFile`, encodeWithParam({fileID : this.branding.logo}));  
        // await hardDeleteFile(this.branding.logo);
        this.refetch();
      });
      // this.$apollo
      //   .mutate({
      //     mutation: SET_CLIENT_LOCATION_LOGO,
      //     variables: {
      //       id: this.$route.params.locationID,
      //       logo: data.id,
      //     },
      //   })
      //   .then(() => {
      //     if (this.branding.logo) {
      //       return hardDeleteFile(this.branding.logo);
      //     }
      //   })
      //   .then(() => this.refetch());
    },
    onChangeColor(color) {
      restApi.post(`/location/updateLocationColor`, encodeWithParam({id : this.$route.params.locationID, color: color})).then(()=>{
        // console.log(data);
        this.branding.color = color;
      });
      // this.$apollo
      //   .mutate({
      //     mutation: SET_CLIENT_LOCATION_COLOR,
      //     variables: {
      //       id: this.$route.params.locationID,
      //       color: color,
      //     },
      //   })
      //   .then(({ data }) => (this.branding.color = data.update_clientLocations.returning[0].color));
    },
  },
  // apollo: {
  //   branding: {
  //     query: GET_CLIENT_LOCATION_BRANDING,
  //     variables() {
  //       return {
  //         clientLocationID: this.$route.params.locationID,
  //       };
  //     },
  //     update: (data) => data.clientLocations[0],
  //   },
  // },
};
</script>
