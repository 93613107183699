import gql from "graphql-tag";

export const SEND_SMS = gql`
  mutation SendSMS($objects: communicationsText_insert_input!) {
    sms: insert_communicationsText_one(object: $objects) {
      id
    }
  }
`;

export const SEND_EMAIL = gql`
  mutation SendEmail($objects: communicationsEmail_insert_input!) {
    email: insert_communicationsEmail_one(object: $objects) {
      id
    }
  }
`;

export const SEND_EMAIL_BULK = gql`
  mutation SendEmail($objects: [communicationsEmail_insert_input!]!) {
    email: insert_communicationsEmail(objects: $objects) {
      affected_rows
    }
  }
`;

export const SEND_SMS_BULK = gql`
  mutation SendSMS($objects: [communicationsText_insert_input!]!) {
    sms: insert_communicationsText(objects: $objects) {
      affected_rows
    }
  }
`;

export const SAVE_PROGRESS_COMMUNICATION = gql`
  mutation saveProgressCommunication($communication: candidateProgressCommunications_insert_input!) {
    insert_candidateProgressCommunications_one(object: $communication) {
      id
    }
  }
`;
